import React, { useEffect, useState, useContext } from "react";
import {
  Box, Typography, CircularProgress, useTheme, Button, TextField
} from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { learncardsContext } from "../../App";
import { API, Auth } from "aws-amplify";
import ReactQuill from 'react-quill'; // Import react-quill
import 'react-quill/dist/quill.snow.css'; // Import react-quill stylesheet

const Dashboard2 = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { loading, setLoading, cardData, setCardData, selectedOption, setSelectedOption } = useContext(learncardsContext);
  const [newSetName, setNewSetName] = useState(""); // State to store the name for the new JS


  const handleProcessRowUpdate = async (updatedRow, oldRow) => {
    console.log("Updating row:", updatedRow);
    const updatedCardData = cardData.data.map((item) => {
      if (item.id === updatedRow.id) {
        return { ...item, ...updatedRow };
      }
      return item;
    });
    setCardData({ ...cardData, data: updatedCardData });
    updateBackend(updatedRow.id, updatedRow);
    return updatedRow;
  };

  const handleProcessRowUpdateError = (error) => {
    console.error("Process row update error:", error);
  };

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleAddRow = async () => {
    const emptyRow = {
      id: generateRandomString(8),
      nr: getHighestNr() + 1,
      front: "TYP HIER",
      back1: "",
      back2: "",
      back3: "",
      hit: "",
      mis: "",
      hit_date: ""
    };

    // Prepend the new row to the existing rows array
    const updatedRows = [emptyRow, ...cardData.data.map(row => ({ ...row, editable: true }))];

    setCardData({ ...cardData, data: updatedRows });
  };
  const getHighestNr = () => {
    let highestNr = 0;
    cardData.data.forEach((item) => {
      const nr = parseInt(item.nr);
      if (!isNaN(nr) && nr > highestNr) {
        highestNr = nr;
      }
    });
    return highestNr;
  };

  const handleDeleteRow = async (rowId) => {
    const updatedRows = cardData.data.filter((item) => item.id !== rowId);
    setCardData({ ...cardData, data: updatedRows });
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const route = `/deleteCard?id=${rowId}&selectie=${selectedOption}`;
      const response = await API.del("FRONTEND_API", route, {
        headers: headers,
      });
      console.log("Delete successful:", response);
    } catch (error) {
      console.error("Error deleting the card:", error);
    }
  };


  const handleCreateSetClick = async () => {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const route = "/createSet"; // API route for creating a new JSON file
      const response = await API.post("FRONTEND_API", route, {
        body: { name: newSetName }, // Send the name for the new JSON file
        headers: headers,
      });
      console.log("Create set successful:", response);
      setSelectedOption(newSetName);
    } catch (error) {
      console.error("Error creating set:", error);
    }
  };



  const updateBackend = async (id, updatedItem) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    try {
      const route = `/updateCard?selectie=${selectedOption}`;
      const response = await API.post("FRONTEND_API", route, {
        body: updatedItem,
        headers: headers,
      });
      console.log("Update successful:", response);
    } catch (error) {
      console.error("Error updating the backend:", error);
    }
  };

  if (!cardData || !cardData.data) {
    return <CircularProgress />;
  }

  const renderHtmlCell = (params) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: params.value }} />
    );
  };


  const screenWidth = window.innerWidth; // Get the width of the screen
  const columns = [
    {
      field: 'nr', headerName: 'Nr', width: 90, editable: true, valueGetter: (params) => {
        return parseInt(params.value, 10);
      },
      sortComparator: (v1, v2) => v1 - v2,
    },
    { field: 'front', headerName: 'Voorkant', width: screenWidth * 0.2, editable: true, renderCell: renderHtmlCell },
    { field: 'back1', headerName: 'Achterkant 1', width: screenWidth * 0.2, editable: true, renderCell: renderHtmlCell },
    { field: 'back2', headerName: 'Achterkant 2', width: screenWidth * 0.2, editable: true, renderCell: renderHtmlCell },
    { field: 'back3', headerName: 'Achterkant 3', width: screenWidth * 0.1, editable: true, renderCell: renderHtmlCell },
    //{ field: 'hit', headerName: 'Hit', width: screenWidth * 0.1, editable: false },
    //{ field: 'mis', headerName: 'Miss', width: screenWidth * 0.1, editable: false },
    //{ field: 'hit_date', headerName: 'Hit Date', width: 200, editable: false },
    {
      field: 'calculatedValue', headerName: 'Calculated Value', width: screenWidth * 0.1, editable: false, valueGetter: (params) => {
        let hit = parseFloat(params.row.hit);
        let mis = parseFloat(params.row.mis);

        // Check if hit or mis are NaN (i.e., not valid numbers)
        if (isNaN(hit)) {
          hit = 0;
        }
        if (isNaN(mis)) {
          mis = 0;
        }

        // Return the calculated value
        return (hit + mis) !== 0 ? ((hit - mis) / (hit + mis)).toFixed(2) : NaN;
      }
    },
    {
      field: 'delete',
      headerName: '',
      width: 20,
      renderCell: (params) => (
        <Typography
          variant="outlined"
          color="error"
          onClick={() => handleDeleteRow(params.row.id)}
        >
          🗑️
        </Typography>
      ),
    },
  ];

  const rows = cardData.data.map((item) => ({
    id: item.id,
    ...item,
  }));

  return (
    <Box m="20px">

      <Button variant="contained" onClick={handleAddRow}>Toevoegen Kaart</Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box mt="20px" >
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            //pageSize={10}
            //rowsPerPageOptions={[1000000]}
            checkboxSelection
            disableSelectionOnClick
            processRowUpdate={handleProcessRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{ toolbar: GridToolbar }}
          />
          <Box mt="20px">
            <TextField
              label="New Set Name"
              value={newSetName}
              onChange={(e) => setNewSetName(e.target.value)}
            />
            <Button variant="contained" onClick={handleCreateSetClick}>Verzameling maken</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};




export default Dashboard2;
