//import { API, Auth } from "aws-amplify";
import { Auth } from "aws-amplify";

export const bijlesbureau_mappings = {
  name: {
    title: "Naam",
    field: "name",
  },
  mail: {
    title: "Email",
    field: "mail",
  },
  interesse_voor: {
    title: "Interesse",
    field: "interesse_voor",
  },
  gemeentes_bijles: {
    title: "Gemeentes",
    field: "gemeentes_bijles",
  },
  studie: {
    title: "Studies",
    field: "studie",
  },
  since: {
    title: "Sinds",
    field: "created",
  },
  key2: {
    fields: "fields",
    fields_human_friendly: "hf",
    title: "title",
    field_1: "field1",
    field_2: "field2",
  },
};

export const handleLogout = async () => {
  try {
    await Auth.signOut();
    // Optionally, you can redirect the user to the login page or perform other actions after successful logout.
    console.log("User signed out");
  } catch (error) {
    console.error("Error signing out:", error);
  }
};
