import { Box, IconButton, useTheme, InputBase, MenuItem, Select } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { handleLogout } from "../../utils";
import { useNavigate } from "react-router-dom";
import { learncardsContext } from "../../App";
import { shuffle } from 'lodash';
import { API, Auth } from "aws-amplify";

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState("unknown");

    const { selectedOption, setSelectedOption, setLoading, setCardData, setOptions, options } = useContext(learncardsContext);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);

    };

    const getOptions = async () => {
        try {
            setLoading(true);
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const route = "/list";

            // Make the API request
            const response = await API.get("FRONTEND_API", route, { headers });

            // Log the response data to check its structure
            console.log("Response data:", response.data);
            //setEmail(response.email);

            // Extract options from the response
            const fetchedOptions = response.data;
            setOptions(fetchedOptions);
            setSelectedOption("Latijn");
        } catch (error) {
            setLoading(false);
            console.error("Error fetching options:", error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        getOptions();
    }, []);

    const getData = async (values) => {
        try {
            setLoading(true);
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const route = "/data?selectie=" + selectedOption;

            // Make the API request
            if (selectedOption) {
                let response = await API.get("FRONTEND_API", route, {
                    body: values,
                    headers: headers,
                });
                // Shuffle the array of cards
                const shuffledData = shuffle(response.data);
                // Set the shuffled cards
                setCardData({ ...response, data: shuffledData });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error updating settings:", error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [selectedOption]);

    return (
        <Box display="flex" justifyContent="space-between" p={2} id="topbar">

            {/* Dropdown Menu */}
            <Select
                value={selectedOption}
                onChange={handleChange}
                style={{ minWidth: 120, marginRight: 10 }}
            >
                {/* Render MenuItem components for each option */}
                {options && options.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
            </Select>
            {/* Search Bar */}

            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box>
            {/* ICONS */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>

                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>

                <IconButton onClick={() => navigate("/settings")}>
                    <SettingsOutlinedIcon />
                </IconButton>

                <Tooltip title={`Logout (${email})`}>
                    <IconButton onClick={handleLogout}>
                        <PersonOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <IconButton></IconButton>
            </Box>
        </Box>
    );
};
export default Topbar;