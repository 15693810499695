import React, { useEffect, useState, useContext } from "react";
import {
  DialogTitle, Box, Button, FormControlLabel, Checkbox, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme, CircularProgress, Grid, Card, CardContent, CardActions, Avatar, Tooltip, TextField, Dialog, DialogContent
} from "@mui/material";
import { tokens } from "../../theme";
import { shuffle } from 'lodash';
import { API, Auth } from "aws-amplify";
import { learncardsContext } from "../../App";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Link } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import CloseIcon from '@mui/icons-material/Close';

import chroma from "chroma-js";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { loading, setLoading, cardData, setCardData, selectedOption } = useContext(learncardsContext);

  const [selectedCard, setSelectedCard] = useState(null); // State to track the selected card
  const [cardCounts, setCardCounts] = useState({}); // State to store hit and mis counts for each card
  const [evaluatingCard, setEvaluatingCard] = useState(null); // State to track which card is being evaluated
  const [startNumber, setStartNumber] = useState(0); // State to store the start number
  const [endNumber, setEndNumber] = useState(100); // State to store the end number
  const [removeChecked, setRemoveChecked] = useState(false); // State to track if "remove" checkbox is checked

  const [cardsToRemove, setCardsToRemove] = useState([]); // State to track cards to remove with fade out effect

  const [sortByAlpha, setSortByAlpha] = useState(false); // State to track whether to sort alphabetically
  const [sortByFormula, setSortByFormula] = useState(false); // State to track whether to sort by formula


  const [exerciseChecked, setExerciseChecked] = useState(false); // State for "Exercise" checkbox

  // const [maxHits, setMaxHits] = useState(null); // State to hold the maximum number of hits
  // const [minMisses, setMinMisses] = useState(null); // State to hold the minimum number of misses

  const [showSettings, setShowSettings] = useState(false); // State to control whether to show additional settings

  const [imageData, setImageData] = useState(null); // State to hold the image data
  const [openImageModal, setOpenImageModal] = useState(false); // State to handle the modal open status

  const [initialLoad, setInitialLoad] = useState(false); // State to handle the modal open status




  // Function to calculate the background color based on sorting formula
  const calculateBackgroundColor2 = (hit, mis) => {
    const formula = calculateSortingFormula(hit, mis);
    // Define your color thresholds and corresponding colors here
    if (formula >= 2) {
      return "#4CAF50"; // Green color for high score
    } else if (formula >= 1) {
      return "#FFEB3B"; // Yellow color for medium score
    } else {
      return "#F44336"; // Red color for low score
    }
  };

  // Function to calculate the background color based on sorting formula
  const calculateBackgroundColor = (hit, mis) => {
    const formula = calculateSortingFormula(hit, mis);
    // Interpolate between colors based on the formula value
    const scale = chroma.scale(['#F44336', '#FFEB3B', '#4CAF50']).domain([-1, 0, 1]);
    return scale(formula).hex();
  };
  // Function to sort cards alphabetically
  // Function to sort cards alphabetically
  const sortCardsByAlpha = (cards) => {
    if (!Array.isArray(cards)) {
      return []; // Return an empty array if cards is not an array
    }
    return cards.slice().sort((a, b) => {
      const nrA = a.nr ? a.nr.toString() : '';
      const nrB = b.nr ? b.nr.toString() : '';
      return nrA.localeCompare(nrB); // Convert to string before comparing
    });
  };

  // Handler for checkbox change for alphabetical sorting
  const handleAlphaCheckboxChange = (event) => {
    setSortByAlpha(event.target.checked);
  };




  // Function to calculate the sorting formula
  const calculateSortingFormula2 = (hit, mis) => {
    return ((hit - mis) * 100) / (hit + mis);
  };

  // Function to calculate the sorting formula
  const calculateSortingFormula = (hit, mis) => {
    // if (hit == "") {
    //   hit = 0;
    // }
    // if (mis == "") {
    //   mis = 0;
    // }
    // if ((hit + mis) == 0) {
    //   return "";
    // }
    return (hit - mis) / (hit + mis);
    //return hit / mis;
  };


  // Function to sort cards by formula
  const sortCardsByFormula = (cards) => {
    if (!Array.isArray(cards)) {
      return []; // Return an empty array if cards is not an array
    }
    return cards.sort((a, b) => {
      const formulaA = calculateSortingFormula(cardCounts[a.id]?.hit, cardCounts[a.id]?.mis);
      const formulaB = calculateSortingFormula(cardCounts[b.id]?.hit, cardCounts[b.id]?.mis);
      // Check if formulaA or formulaB is NaN
      if (isNaN(formulaA) && isNaN(formulaB)) {
        return 0; // Treat two NaNs as equal
      } else if (isNaN(formulaA)) {
        return -1; // Place NaNs at the end
      } else if (isNaN(formulaB)) {
        return 1; // Place NaNs at the end
      }
      //return formulaB - formulaA; // Descending order      
      return formulaA - formulaB; // Ascending order
    });
  };
  // Handler for checkbox change
  const handleCheckboxChange = (event) => {
    setSortByFormula(event.target.checked);
  };

  // Handler for "remove" checkbox change
  const handleRemoveCheckboxChange = (event) => {
    setRemoveChecked(event.target.checked);
  };

  const handleExerciseCheckboxChange = (event) => {
    setExerciseChecked(event.target.checked);
  };

  useEffect(() => {
    // Initialize hit and mis counts for each card from cardData
    // don't do when excercise mode otherwise setCardCounts gets refreshed
    if (cardData && cardData.data && (!exerciseChecked)) {
      const counts = {};
      cardData.data.forEach((item) => {
        counts[item.id] = { hit: item.hit, mis: item.mis };
      });
      setCardCounts(counts);
    }
  }, [cardData]);


  // useEffect(() => {
  //   if (!initialLoad) {
  //     setInitialLoad(true);
  //     if (cardData && cardData.data) {
  //       const allNumbers = cardData.data.every(item => !isNaN(Number(item.nr)));
  //       setSortByAlpha(!allNumbers);
  //       setSortByFormula(allNumbers);
  //     }

  //   }
  // }, [selectedOption]);


  const handleCardClick = async (randomId) => {
    // Check if the card is being selected (flipped to show the back)
    const isSelected = selectedCard === randomId;
    setSelectedCard(isSelected ? null : randomId);

    if (!isSelected) {
      // Check if back1 contains "jpg" or "png"
      const cardWithImage = cardsToDisplay.find(card => card.id === randomId && (card.back1.includes("jpg") || card.back1.includes("png")));

      if (cardWithImage) {
        try {
          const session = await Auth.currentSession();
          const accessToken = session.getAccessToken().getJwtToken();
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const route = `/image?selectie=${selectedOption}&filename=${cardWithImage.back1}&id=${cardWithImage.id}`;
          const response = await API.get("FRONTEND_API", route, {
            headers: headers,
          });
          if (response && response.image_data_base64) {
            // Handle the response
            const { image_data_base64, content_type } = response;
            setImageData(`data:${content_type};base64,${image_data_base64}`);
            setOpenImageModal(true); // Open the modal with the image
          } else {
            console.error('Failed to fetch image:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      }
    } else {
      setImageData(null); // Clear image data if the card is being deselected
    }
  };


  const handleThumbUpClick = async (id) => {
    try {
      if (!exerciseChecked) { // Check if "Exercise" mode is off
        // Call the evaluate API
        await callEvaluateAPI(id, "+");
      } else {
        // Update the card counts locally without calling the API
        setCardCounts(prevCounts => ({
          ...prevCounts,
          [id]: {
            hit: (prevCounts[id]?.hit || 0) + 1,
            mis: prevCounts[id]?.mis || 0
          }
        }));

        // If  exerciseChecked is true, remove the card anyways
        if (exerciseChecked) {
          setCardsToRemove(prevCards => [...prevCards, id]);

          setTimeout(() => {
            setCardData(prevCardData => ({
              ...prevCardData,
              data: prevCardData.data.filter(card => card.id !== id)
            }));
          }, 500);
        }
      }
    } catch (error) {
      console.error("Error evaluating card:", error);
    }
  };
  const handleThumbDownClick = async (id) => {
    try {
      if (!exerciseChecked) {
        await callEvaluateAPI(id, "-");
      } else {
        // Increment miss count
        setCardCounts(prevCounts => ({
          ...prevCounts,
          [id]: {
            hit: prevCounts[id]?.hit || 0,
            mis: (prevCounts[id]?.mis || 0) + 1
          }
        }));

        // Find the index of the card in the data array
        const index = cardData.data.findIndex(card => card.id === id);

        // Move the card to the end of the array
        const updatedData = [...cardData.data];
        const movedCard = updatedData.splice(index, 1)[0];
        updatedData.push(movedCard);

        // Update the card data
        setCardData(prevCardData => ({
          ...prevCardData,
          data: updatedData
        }));
      }
    } catch (error) {
      console.error("Error evaluating card:", error);
    }
  };


  const callEvaluateAPI = async (id, evaluation) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const route = "/evaluate?selectie=" + selectedOption;

    setEvaluatingCard(id);

    const response = await API.post("FRONTEND_API", route, {
      body: {
        id: id,
        evaluation: evaluation
      },
      headers: headers,
    });

    setCardCounts(prevCounts => ({
      ...prevCounts,
      [id]: { hit: response.hit, mis: response.mis }
    }));

    if (removeChecked) {
      setCardsToRemove(prevCards => [...prevCards, id]);

      setTimeout(() => {
        setCardData(prevCardData => ({
          ...prevCardData,
          data: prevCardData.data.filter(card => card.id !== id)
        }));
      }, 500);
    }

    setEvaluatingCard(null);
  };

  // Filter cards based on start and end number    
  const filteredCards = cardData && cardData.data && cardData.data.filter(item => {
    const cardNumber = parseInt(item.nr);
    const hits = cardCounts[item.id]?.hit;
    const misses = cardCounts[item.id]?.mis;

    // Check if startNumber and endNumber are not numbers
    const areNumbers = !isNaN(startNumber) && !isNaN(endNumber);

    // Apply filter condition based on startNumber and endNumber if both are numbers
    if (areNumbers) {
      return cardNumber >= startNumber && cardNumber <= endNumber;
    } else {
      // Return true if either startNumber or endNumber is not a number
      return true;
    }
  });


  //const cardsToDisplay = sortByFormula ? sortCardsByFormula(filteredCards) : filteredCards;  
  const cardsToDisplay = sortByAlpha ? sortCardsByAlpha(filteredCards) : sortByFormula ? sortCardsByFormula(filteredCards) : filteredCards;

  return (
    <Box m="20px">
      {/* Input fields for start and end numbers */}
      <Box mb={2}>
        <TextField
          label="Start Nummer"
          type="number"
          value={startNumber}
          onChange={(e) => setStartNumber(parseInt(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: 10 }}
        />
        <TextField
          label="Eind Nummer"
          type="number"
          value={endNumber}
          onChange={(e) => setEndNumber(parseInt(e.target.value))}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {/* Checkbox for Exercise */}
        <FormControlLabel
          sx={{ marginLeft: "50px" }}
          control={<Checkbox checked={exerciseChecked} onChange={handleExerciseCheckboxChange} />}
          label="Oefenen"
        />

      </Box>
      <Accordion sx={{ marginBottom: "10px" }} expanded={showSettings} onChange={() => setShowSettings(!showSettings)}>
        <AccordionSummary>
          <Typography>Instellingen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Your additional settings here */}
          {/* For example, you can put the checkboxes here */}
          <Box display="flex" flexDirection="column">
            {/* Checkbox for sorting by formula */}
            <FormControlLabel
              control={<Checkbox checked={sortByFormula} onChange={handleCheckboxChange} />}
              label="Sorteer volgens Kleur"
            />
            {/* Checkbox for sorting alphabetically */}
            <FormControlLabel
              control={<Checkbox checked={sortByAlpha} onChange={handleAlphaCheckboxChange} />}
              label="Sorteer volgens alphabet"
            />
            {/* Checkbox for removing cards */}
            <FormControlLabel
              control={<Checkbox checked={removeChecked} onChange={handleRemoveCheckboxChange} />}
              label="Verwijder"
            />


            <TextField
              label="Max Hits"
              type="number"
              //value={maxHits || ""}
              //onChange={(e) => setMaxHits(parseInt(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: 10 }}
            />
            <TextField
              label="Min Misses"
              type="number"
              // value={minMisses || ""}
              //onChange={(e) => setMinMisses(parseInt(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: 10 }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        gridAutoRows="140px"
        gap="20px"
      >
        {loading ? (
          <CircularProgress />
        ) : (
          cardsToDisplay && cardsToDisplay.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Card
                className={`card-${item.id}`} // Add the random ID as a class name
                onClick={() => handleCardClick(item.id)}
                style={{ cursor: "pointer", backgroundColor: calculateBackgroundColor(cardCounts[item.id]?.hit, cardCounts[item.id]?.mis) }}
              >
                <CardContent style={{ display: "flex", alignItems: "center", minHeight: "80px" }}>
                  <Tooltip title={`Formula: ${calculateSortingFormula(cardCounts[item.id]?.hit, cardCounts[item.id]?.mis)}`}>
                    <Avatar>
                      {evaluatingCard === item.id ? <CircularProgress /> : <LightbulbIcon />}
                    </Avatar>
                  </Tooltip>
                  <Typography variant="body2" color="textPrimary" style={{ marginLeft: 10 }}>
                    {selectedCard === item.id ? (
                      <>
                        {/**  BACK OF CARD**/}  {/* Check if image data is available */}

                        <>
                          {/**<Typography variant="body2" color="textPrimary" style={{ fontWeight: "bold" }}>{item.front}</Typography>**/}
                          <Typography variant="body2" color="textPrimary">
                            <span dangerouslySetInnerHTML={{ __html: item.back1 }} />
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            <span dangerouslySetInnerHTML={{ __html: item.back2 }} />
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            <span dangerouslySetInnerHTML={{ __html: item.back3 }} />
                          </Typography>
                        </>

                      </>
                    ) : (
                      <>
                        {/**  FRONT OF CARD**/}
                        <Typography variant="body2" color="textPrimary" style={{ fontWeight: "bold" }}>{isNaN(Number(item.nr)) ? `${item.nr}` : ''}</Typography>
                        <Typography variant="body2" color="textPrimary">{item.front} {isNaN(Number(item.nr)) ? '' : `(${item.nr})`}</Typography>
                      </>
                    )}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  {selectedCard === item.id ? (
                    <>
                      <IconButton>
                        <Tooltip title={`Draai om`}>
                          <AutorenewIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        aria-label="thumb up"
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event propagation
                          handleThumbUpClick(item.id);
                        }}
                      >
                        <Tooltip title={`Hit Date: ${item.hit_date}`}>
                          <ThumbUpOffAltIcon />
                        </Tooltip>
                        <Typography>{cardCounts[item.id]?.hit}</Typography>
                      </IconButton>
                      <IconButton
                        aria-label="thumb down"
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event propagation
                          handleThumbDownClick(item.id);
                        }}
                      >
                        <Tooltip title={`Mis Date: ${item.mis_date}`}>
                          <ThumbDownOffAltIcon />
                        </Tooltip>
                        <Typography>{cardCounts[item.id]?.mis}</Typography>
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton aria-label="favorite">

                        <Tooltip title={`Draai om`}>
                          <AutorenewIcon />
                        </Tooltip>
                      </IconButton>

                    </>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
        <Dialog
          open={openImageModal}
          onClose={() => setOpenImageModal(false)}
          fullScreen
          PaperProps={{
            style: {
              width: '87vw',
              //height: '90vh',
              maxWidth: 'none',
              maxHeight: 'none',
              margin: 'auto'
            }
          }}
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => setOpenImageModal(false)}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {imageData && (
              <img src={imageData} alt="Card Image"
              //style={{ width: '100%', height: '100%' }} 
              />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Dashboard;
