const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  meta: {
    STAGE: process.env.REACT_APP_STAGE,
    VERSION: process.env.REACT_APP_VERSION,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL,
    //URL: process.env.REACT_APP_API_URL,
    //URL2: process.env.REACT_APP_API_URL2,
    //URL3: process.env.REACT_APP_API_URL3,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};

export default config;
