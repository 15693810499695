import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
//import { default as Form, default as SettingsForm } from "./scenes/form";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
//import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import Dashboard2 from "./scenes/dashboard2";

export const learncardsContext = React.createContext();

function App({ gitRevision, stage }) {
  const [theme, colorMode] = useMode();

  const [loading, setLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");

  const [cardData, setCardData] = useState("");

  const [selectedOption, setSelectedOption] = useState("");

  const [options, setOptions] = useState([]);

  console.log("Options:", options); // Log the value of options


  return (
    <learncardsContext.Provider
      value={{
        loading,
        setLoading,
        alertMessage,
        setAlertMessage,
        cardData,
        setCardData,
        selectedOption,
        setSelectedOption,
        setOptions,
        options

      }}
    >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={`app ${stage} `}>
            <Sidebar />
            <main className="content">
              <Topbar />
              <Routes>
                {<Route path="/" element={<Dashboard />} />}
                {<Route path="/beheer" element={<Dashboard2 />} />}
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </learncardsContext.Provider>
  );
}

export default App;
//export default withAuthenticator(App);
