import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import config from "./config";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "FRONTEND_API",
        endpoint: config.apiGateway.REACT_APP_API_URL,
        region: config.apiGateway.REGION,
      },
      //{
      //  name: "PythonApi",
      //  endpoint: config.apiGateway.URL2,
      //     //  region: config.apiGateway.REGION,
      //      //},
      //      //{
      //      //  name: "RApi",
      //  endpoint: config.apiGateway.//3,
      //  region: config.apiGateway.REGION,
      //},
      //{
      //  name: "notes2",
      //  endpoint: config.apiGateway.URL,
      //  region: config.apiGateway.REGION,
      //},
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
// WRAP APP with <Authenticator> for authentication STIJN
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Authenticator hideSignUp={false}>
        <App gitRevision={config.meta.VERSION} stage={config.meta.STAGE} />
      </Authenticator>
    </BrowserRouter>
  </React.StrictMode>
);
